import './App.css';
import logo from "./images/logo1.png";
import QR from "./images/qr.jpg";
import 'rsuite/dist/rsuite.min.css';
import PlayStore from "./images/playsotre.png";
import AppStore from "./images/appstore.png";
import { Input, InputGroup } from 'rsuite';
import { Button } from 'rsuite';
import { Box, Image, Badge } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa'; // Import the loading spinner icon

import { ChakraProvider, Progress } from "@chakra-ui/react"



import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';

function App() {
  const [videos, setVideos] = useState([]);
  const [maxCursor, setMaxCursor] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [downloadCount, setDownloadCount] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const cancelRef = useRef();
  const [latestDownloadMessage, setLatestDownloadMessage] = useState('');
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  const defaultTitle = `video_${timestamp}`;
  const [cancelDownload, setCancelDownload] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [zipCreated, setZipCreated] = useState(false);
  const [downloaded, setDownloaded] = useState(0);
  const [stopRequest, setStopRequest] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [videosInRow, setVideosInRow] = useState(6);
  const stopRequestRef = useRef(false);
  const [apiKey, setApiKey] = useState('');



  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    setSortOrder(newSortOrder);
  };

  const sortVideosByPlayCount = () => {
    const sortedVideos = [...videos];
    sortedVideos.sort((a, b) => {
      if (sortOrder === 'desc') {
        return b.play_count - a.play_count;
      } else {
        return a.play_count - b.play_count;
      }
    });
    return sortedVideos;
  };

  const formatPlayCount = (count) => {
    if (count >= 1000 && count < 1000000) {
      // Display in 'k' format
      return `${(count / 1000).toFixed(0)}k`;
    } else if (count >= 1000000) {
      // Display in 'M' format
      return `${(count / 1000000).toFixed(1)}M`;
    } else {
      // Display the count as it is
      return count.toString();
    }
  };






  const onCancel = () => {
    setCancelDownload(true);
    setIsOpen(false); // Close the modal
  };

  const onClose = () => {
    setCancelDownload(true);
    setIsOpen(false);
  };


  const extractUsernameFromUrl = (url) => {
    const match = url.match(/@([^\/?]+)/);
    return match ? `@${match[1]}` : null;
  };

  const handleInputChange = (value) => {
    setInputValue(value);

    // Extract the TikTok username from the input value, including the '@' symbol
    const extractedUsername = extractUsernameFromUrl(value);

    // Set the extracted username in the state
    setUsername(extractedUsername || '');
  };



  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 600; // Adjust this threshold based on your design
      setVideosInRow(isMobile ? 2 : 6);
    };

    // Initial setup
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleDownloadTxt = async () => {
    if (!apiKey) {
      alert("Please enter your API key.");
      return;
    }
    const videoCount = selectedVideos.length;
    const checkBalanceUrl = `https://apifarmreel.mmoshop.me/check_balance/savetiktok?api_key=${apiKey}&video_count=${videoCount}`;

    try {
      const balanceResponse = await axios.get(checkBalanceUrl);

      if (balanceResponse.data.status === 500) {
        alert(balanceResponse.data.message); // Assuming the message has the error/balance details
      } else if (balanceResponse.data.cost != null) {
        // Fetch each video's URL using its video_id
        const videosWithUrls = await Promise.all(selectedVideos.map(async (video) => {
          try {
            const response = await axios.get(`https://apifarmreel.mmoshop.me/getvideourl?video_id=${video.video_id}`);
            // Assuming the response contains { url_video: "video_url_here" }
            return {
              ...video,
              url_video: response.data.url_video,
            };
          } catch (error) {
            console.error(`Error fetching URL for video ID ${video.video_id}:`, error);
            return null; // Skipping videos for which URL couldn't be fetched
          }
        }));

        // Filter out any videos for which the URL couldn't be fetched
        const validVideos = videosWithUrls.filter(video => video !== null);

        if (validVideos.length > 0) {
          const dataToDownload = {
            profile: username, // Assuming 'username' is defined in your scope
            videos: validVideos.map(({ title, url_video }) => ({
              title,
              url_video,
            }))
          };

          const textContent = JSON.stringify(dataToDownload, null, 2);
          const element = document.createElement('a');
          const file = new Blob([textContent], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = `${username || 'download'}.txt`;
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          alert("There was an issue fetching video URLs.");
        }
      } else {
        alert("Insufficient balance for the requested operation.");
      }
    } catch (error) {
      console.error("Error checking balance or downloading file:", error);
    }
  };





  const handleStopRequest = () => {
    stopRequestRef.current = true;
  };


  const check_balance_api = async () => {
    if (!apiKey) {
      alert("Please enter your API key.");
      return; // Early return if API key is not set
    }

    const checkBalanceUrl = `https://apifarmreel.mmoshop.me/api/check_balance?apikey=${apiKey}`;

    try {
      const balanceResponse = await axios.get(checkBalanceUrl);
      // Assuming the balance can also indicate an error with the API key in your API design
      if (balanceResponse.data.balance === "Please check the API key again.") {
        alert("Please check the API key again.");
      } else {
        // Display the balance
        alert(`Your balance: ${balanceResponse.data.balance}`);
      }
    } catch (error) {
      console.error("Error checking balance:", error);
      alert("Please check the API key again.");
    }
  };

  const fetchData = async (username, cursor) => {
    setLoading(true);
    setVideos([]);
    setMaxCursor(null);
    stopRequestRef.current = false; // Reset the stop flag at the start of a fetch

    try {
      while (cursor !== null && !stopRequestRef.current) {
        const response = await axios.get(`https://apifarmreel.mmoshop.me/getvideoas/tiktok?username=${username}&max_cursor=${cursor || ''}`, {
          headers: { accept: 'application/json' },
        });

        if (response.data.max_cursor !== null && !stopRequestRef.current) {
          const newVideos = response.data.videos;
          setVideos((prevVideos) => [...prevVideos, ...newVideos]);
          cursor = response.data.max_cursor;
          setMaxCursor(cursor);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          cursor = null;
        }
      }
    } catch (error) {
      console.log(error);
      setMaxCursor(null);
    } finally {
      setLoading(false);
    }
  };






  const handleSelectAll = () => {
    if (selectedVideos.length === videos.length) {
      // All videos are selected, so unselect all
      setSelectedVideos([]);
      setDownloadCount(0);
    } else {
      // Not all videos are selected, so select all
      setSelectedVideos([...videos]);
      setDownloadCount(videos.length);
    }
  };

  const handleVideoSelect = (video) => {
    if (selectedVideos.includes(video)) {
      setSelectedVideos(selectedVideos.filter((v) => v !== video));
    } else {
      setSelectedVideos([...selectedVideos, video]);
    }
  };
  


  const handleDownloadSelected = async () => {
    if (selectedVideos.length === 0) {
      return;
    }

    try {
      setIsOpen(true);
      setDownloaded(0);
      setShowLoading(true);
      setDownloadLoading(true);
      const zip = new JSZip();

      // Use a Map to keep track of the count for each video title
      const titleCountMap = new Map();

      // Use Promise.all to wait for all downloads to complete
      await Promise.all(
        selectedVideos.map(async (video, index) => {
          if (cancelDownload) {
            toast.error(`Download STOP`);
            throw new Error('Download stopped');
          }

          try {
            const response = await axios.get(`https://apifarmreel.mmoshop.me/get_video?video_url=${encodeURIComponent(video.url_video)}`, {
              responseType: 'blob',
              onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                  const loaded = progressEvent.loaded;
                  const total = progressEvent.total;
                  const percentCompleted = Math.round((loaded * 100) / total);

                  // Update the download progress and message
                  setDownloadProgress(percentCompleted);
                  setLatestDownloadMessage(`Video ${index + 1} (${video.title}) - ${percentCompleted}%`);
                }
              },
            });

            setDownloadProgress(100);

            // Increment the count for the current video title
            const title = video.title || 'Untitled';
            const count = titleCountMap.get(title) || 0;
            titleCountMap.set(title, count + 1);

            // Append the count to the file name
            let fileName = `${title}_${count + 1}.mp4`;
            zip.file(fileName, response.data, { binary: true });
            const delayDuration = 2000;
            await new Promise(resolve => setTimeout(resolve, delayDuration));

          } catch (error) {
            console.error(`Error downloading video ${index + 1}:`, error);
          } finally {
            // Increment the downloaded count after each video
            setDownloaded((prevDownloaded) => prevDownloaded + 1);
          }
        })
      );

      // Generate the zip file after all downloads are complete
      const content = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      const zipFilename = `${username}.zip`;
      link.download = zipFilename;
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);

      setShowLoading(false);
      setDownloadProgress(undefined);
      toast.success('All downloads complete!');
      setZipCreated(true);
    } catch (error) {
      console.error('Error downloading videos:', error);
      setShowLoading(false);
      setDownloadProgress(undefined);
      toast.error('Error downloading videos');
      setDownloadLoading(false);
    } finally {
      setIsOpen(false);
    }
  };


  return (
    <div className="App">
      <div className='h-20 flex justify-around items-center text-center '>
        <div className="cursor-pointer" >
          <img className='' src={logo} alt="" width={220} />
        </div>

        <div className='flex space-x-8 '>
          <div className="cursor-pointer" onClick={() => window.open('https://www.facebook.com/MMOShopOfficial', '_blank')}>
            <h5 className='meun-text'>CONTACT</h5>
          </div>
        </div>
      </div>

      <div className='background-button'>
        <div className='flex justify-around'>
          <div className=''>
            <div className='p-10'>
              <h2>
                Download all Videos from TikTok.
              </h2>
            </div>
            <div className='p-4 flex space-x-2'>
              <Input
                placeholder='APIKEY'
                value={apiKey}
                onChange={(value) => setApiKey(value)}
              />
              <Button className="custom-button" width={100} onClick={check_balance_api}>Check Balance</Button>
            </div>
            <div className='p-4 flex space-x-2'>
              <InputGroup inside className=''>
                <Input value={username} onChange={handleInputChange} placeholder='Paste URL Profile' />

                {/* <InputGroup.Addon>
                  <SearchIcon color='white' />
                </InputGroup.Addon> */}
              </InputGroup>
              <Button className="custom-button" width={100} onClick={() => fetchData(username)}>
                {loading ? <FaSpinner className="animate-spin" size={20} /> : "START"}
              </Button>
              <Button className="custom-button" width={100} onClick={handleStopRequest}>STOP</Button>




            </div>
            <div className='p-5 flex space-x-10 items-center'>
              <div className='' >

                {/* <img className='' src={QR} alt="" width={170} style={{backgroundColor:'white', padding:'10px', borderRadius:'10px'}}/> */}
              </div>
              <div>
                {/* <h3>DONATE | scan with KHQR</h3> */}
                {/* <h5>scan with KHQR</h5> */}
              </div>


            </div>
          </div>
        </div>
      </div>



      <div className='p-4'>Total Videos: {videos.length}</div>
      <div className='space-x-2'>
      <Button onClick={handleSelectAll}>
  {selectedVideos.length > 0 ? `Selected ${selectedVideos.length}` : 'Select Videos'}
</Button>



        <Button onClick={handleDownloadTxt}>Download Info as Text</Button>

        {/* <Button onClick={() => handleDownloadSelected()} disabled={selectedVideos.length === 0}>
            Download Selected {downloadCount}
          </Button> */}
        <Button className="custom-button" onClick={toggleSortOrder}>
          {sortOrder === 'desc' ? 'Sort Descending' : 'Sort Ascending'}
        </Button>
      </div>
      <ChakraProvider >
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader></AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {downloadLoading ? (
                <div className="text-center flex flex-col items-center">
                  {loading && (
                    <div className="loading-spinner">
                      {/* Your loading spinner component */}
                      <FaSpinner className="animate-spin" size={40} /> {/* Example spinner */}
                    </div>
                  )}
                  <Progress value={downloadProgress} size="sm" colorScheme="teal" />
                  <p>
                    Downloading... {downloaded}/{selectedVideos.length}
                  </p>
                  {latestDownloadMessage && <p>{latestDownloadMessage}</p>}
                </div>
              ) : (
                <div className="text-center">
                  {zipCreated ? (
                    <p>Download complete!</p>
                  ) : (
                    <p>
                      Download in progress... {downloaded}/{selectedVideos.length}
                    </p>
                  )}
                </div>
              )}

            </AlertDialogBody>



            <AlertDialogFooter>
              {/* <Button ref={cancelRef} onClick={onClose}>
                    No
                  </Button>
                  <Button colorScheme='red' ml={3} onClick={handleDownloadSelected}>
                    Download
                  </Button> */}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </ChakraProvider>


      <div className='y-10' style={{ minHeight: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* <div className='y-10' style={{ minHeight: "100vh", display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}> */}
        <div className='p-4' style={{ minHeight: "10vh", width: '100vh', textAlign: 'center' }}>
          <div className=''>
            <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2'>
              {sortVideosByPlayCount().map((video, index) => (
                <div className="p-0 " key={index} >
                  <Box
                    className={`shadow-md  ${selectedVideos.includes(video) ? 'selected-video' : ''}`}
                    maxW="sm"
                    borderWidth="3px"
                    borderRadius="lg"
                    overflow="hidden"
                    style={{ borderRadius: "8px" }}
                    onClick={() => handleVideoSelect(video)}
                  >
                    <Image
                      src={video.cover}
                      width="100%"
                      height="100%"
                      alt={video.title}
                      objectFit="cover"
                    />
                    <Box p='6'>
                      <Box display='flex' alignItems='baseline'>
                        <Badge borderRadius='full' px='2' colorScheme='teal'>
                          Play : {formatPlayCount(video.play_count)}
                        </Badge>
                      </Box>

                      <Box
                        mt='1'
                        textAlign='start'
                        fontWeight='semibold'
                        as='h6'
                        lineHeight='tight'
                        noOfLines={10}
                      >
                        {video.title}
                      </Box>

                      <Box>
                        {video.formattedPrice}
                        <Box as='span' color='gray.600' fontSize='sm'></Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              ))}
            </div>
          </div>
          <ToastContainer />


          <div className='grid mt-12 '>
            <div className='text-start space-y-2 pt-5'>
              <h3>
                Download TikTok videos (Musically) Without Watermark for FREE
              </h3>
              <p>
                Save TikTok is one of the best TikTok Downloader available online to Download video tiktok without a watermark. You are not required to install any software on your computer or mobile phone, all that you need is a TikTok video link, and all the processing is done on our side so you can be one click away from downloading videos to your devices.
              </p>
            </div>

            <div className='text-start pt-5'>
              <h3>
                Key features:
              </h3>
              <p className='p-2'>
                No watermark for better quality, which most of the tools out there can't.
                Download TikTok videos, Musically videos on any devices that you want: mobile, PC, or tablet. TikTok only allows users to download videos by its application and downloaded videos contain the watermark.
                Download by using your browsers: I want to keep things simple for you. No need to download or install any software. I make an application for this purpose as well but you can only install whenever you like.
                It's always free. I only place some ads, which support maintaining our services, and further development.
                New SnapTik provides users with the ability to download Tiktok's photo slide show as Mp4 Video format. The images and music in the Tiktok slide show will be automatically merged by SnapTik. In addition, you can also download each image in the slide show to your computer right away.
              </p>

              <p className='p-2'>
                Note: Save TikTok (Tiktok video Downloader) is not a tool of Tiktok, we have no relationship with Tiktok or ByteDance Ltd. We only support Tiktok users to download our videos on Tiktok without logo without any trouble. If you have problems with sites like Tikmate or SSSTiktok, try SnapTik, we are constantly updating to make it easy for users to download tiktok videos. Thank you!
              </p>
            </div>

            <div className='pt-5'>
              <div class="bg-gradient-to-r from-lime-500 to-lime-200 app-box flex justify-between items-center text-left p-5 flex-col xl:flex-row" style={{ gap: '20px', color: 'white', borderRadius: '20px' }}>
                <div class="app-box__info p-2">
                  <h3 class="app-box__title">Download with app Save TikTok</h3>
                  <p>We now provide Save TikTok video Download. It is fast, easy, with no watermark and HD quality</p>
                </div>
                <div class="app-link flex">
                  <img className='' src={PlayStore} alt="" width={160} style={{ padding: '5px' }} />
                  <img className='' src={AppStore} alt="" width={160} style={{ padding: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
